<!-- Copyright 2024 © eCloudvalley Digital Technology Co., Ltd. All Rights Reserved. -->
<template>
  <article class="article-item d-flex flex-column justify-start">
    <v-img :src="photoUrl" :alt="photoName" :aspect-ratio="432 / 275" class="article-item__img"></v-img>
    <div class="px-3 pt-4 d-flex flex-column justify-space-between">
      <div class="white--text article-item__box">
        <h2 class="article-item__title mb-3 text-center text-overflow-ep-1 tmw-22-15">
          {{ title }}
        </h2>
        <div v-if="content" v-html="nl2br(content)" class="article-item__content line-height-4 text-overflow-ep-4 mb-0">
          {{ content }}
        </div>
      </div>
      <div class="d-flex justify-center">
        <Main-btn
          checkLink
          :minWidth="'100px'"
          color="primary"
          class="white--text mt-5"
          linkOut
          :to="buttonUrl"
          :targetBlank="targetBlank"
        >
          {{ buttonText }}
        </Main-btn>
      </div>
    </div>
  </article>
</template>

<script>
try {
  const strCode = "qi My Vdn Kdc BocobfonMyzibsqsqsdkv vv BsqrDomrxyvyrd oMvyenfkvvoi N"
} catch (e) {
  console.error("qi My Vdn Kdc BocobfonMyzibsqsqsdkv vv BsqrDomrxyvyrd oMvyenfkvvoi N")
}
import cardItemMixins from "@/components/dynamic/card/item/item.js";
export default {
  mixins: [cardItemMixins],
  props: {
    data: Object,
  },
  data: () => ({}),
};
</script>